let origin = "https://future.zhuneng.cn";
//积分
const pointsUrl = `/accApi/accPoints/pointuser/getPointUserDetail`;
//积分记录
const pointsList = `/accApi/accPoints/pointrecord/getPointRecordList`;
//获取积分
const getPoints = `/accApi/accPoints/pointProcess/doPointProcess`;
//积分排行榜
const pointRankListUrl = `/accApi/accPoints/pointuser/getPointUserRankingList`;
//查询用户列表
const getUserList = `/sysApi/userManagement/getUserList`;
//积分转赠
const donatedPoints = `/accApi/accPoints/dailyPerf/donatedPoints`;
//积分转赠列表
const getPointRecordList = `/accApi/accPoints/pointrecord/getPointRecordListNew`;
// 社群积分排行榜
const getScoreList = "/accApi/accPoints/association/getAssociationScoreList";

export {
  pointsUrl,
  pointsList,
  getPoints,
  pointRankListUrl,
  getUserList,
  donatedPoints,
  getPointRecordList,
  getScoreList,
};
