<template>
  <div class="pointIncrease">
    <div class="box">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="pic"><img :src="item.avatar" alt="" /></div>
          <div class="name">{{ item.nickName }}</div>
          <div class="time">{{ item.insertTime }}</div>
          <div
            :class="item.operationType == 0 ? 'points_orange' : 'points_black'"
          >
            <span>{{ item.operationType == 0 ? "-" : "+" }}</span
            >{{ item.pointCount }}
          </div>
          <div class="line"></div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { getPointRecordList } from "./api.js";
export default {
  name: "transferRecord",
  data() {
    return {
      list: [],
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
    };
  },
  computed: {
    userId() {
      //当前用户ID
      return this.$store.state.userId;
    },
    tenantId() {
      //当前租户ID
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        userName: "",
        recordType: 99,
        operationType: null,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios
        .post(`${getPointRecordList}`, params, {
          headers: { "Content-Type": "application/json; charset=UTF-8" },
        })
        .then((res) => {
          if (res.code === 200) {
            console.log(res.data.records);
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.list.push(item);
            });
          } else {
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.pointIncrease {
    min-height: 100vh;
    background: #f5f5f5;

    .item {
        background: #fff;
        width: 100%;
        height: 132px;
        position: relative;

        .line {
            width: 718px;
            height: 1px;
            background: rgba(0, 0, 0, 0.06);
            position: absolute;
            bottom: 0;
            right: 0;
        }

        .pic {
            width: 80px;
            height: 80px;
            position: absolute;
            top: 50%;
            left: 32px;
            transform: translateY(-50%);
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .name {
            color: #333333;
            font-size: 32px;
            position: absolute;
            top: 24px;
            left: 142px;
        }

        .time {
            color: #cccccc;
            font-size: 24px;
            position: absolute;
            top: 76px;
            left: 142px;
        }

        .points_black {
            color: #000000;
            font-size: 36px;
            position: absolute;
            top: 50%;
            right: 32px;
            transform: translateY(-50%);
        }

        .points_orange {
            color: #FE6F16;
            font-size: 36px;
            position: absolute;
            top: 50%;
            right: 32px;
            transform: translateY(-50%);
        }
    }
  }
</style>
